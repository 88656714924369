import React from "react";
import LessonLayout from "~/layouts/lesson";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Unordered from "~/components/List/Unordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={3}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 3</H2>

          <p>Marketing companies study how to influence people to buy their products or buy into their causes. You have just learned about some of them. These strategies can all be used for good reasons—and they can be used to mislead people. An ad may use more than one of these strategies.</p>

          <p>Click on the link below to see an ad for the lemonade diet cleanse.</p>

          <Unordered>
            <li>Can you identify any persuasion strategies in the ad?</li>
            <li>What information on the site supports the persuasion strategy or strategies you identified?</li>
          </Unordered>

          <p>Record your answers to these questions in your science notebook.</p>

          <Button
            action="secondary"
            color="purple"
            href="/article/lemonade-diet"
            icon="external"
            title="Lemonade diet cleanse website"
          >
            Lemonade diet cleanse website
          </Button>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
